import React, { useEffect } from 'react';
import Slide from './Slide';
import Appprop from './Appprop';
import Serveprop from './Serveprop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    useEffect(() => {
        AOS.init();
    })
    return (
        <>

            <div className="slide-main" id="home">
                <div className="slide-inner-main">
                    <Slide />
                </div>
            </div>

            <div className="text-main">
                <div className="container">
                    <div className="text-inner-main">
                        <p> " We Simplify your Business through the use of cutting edge Digital Solutions and Services as Lifeline for your Organization. "</p>
                    </div>
                </div>
            </div>
            <div className="app-main">
                <div className="container">
                    <div className="app-text">
                        <p>Intelligent Software Solutions</p>
                    </div>
                    <div className="app-inner-main" data-aos="zoom-in" data-aos-duration="1000">
                        <Appprop img={"assents/gif/machinegif.gif"} h3={"Machine-integrated Solutions"} p={"Our custom software development service addresses your needs when specific requirements are not adequately met by packaged software. We innovate solutions to satisfy your exact requirements."} />


                        <Appprop img={"assents/gif/app.gif"} h3={"Mobile Application"} p={"We provide comprehensive mobile solutions for a wide range of clients ranging from small startups to enterprise level. We have expertise in providing the power of Mobile Apps for complex businesses."} />

                        <Appprop img={"assents/gif/web.gif"} h3={"Web  Application"} p={"GWe offer product development services for developing, implementing innovative software applications. We build software products that information and drive revenue growth for our clients"} />

                        <Appprop img={"assents/gif/productgif.gif"} h3={"Product Development"} p={"We offer product development services for developing, implementing innovative software applications. We build software products that information and drive revenue growth for our clients."} />

                        <Appprop img={"assents/gif/microsoftgif.gif"} h3={"Windows Application"} p={"GalaxyERP has experience of delivering high-performance windows and web applications, even for complex functionalities with a visually rich interface and stringent quality."} />

                        <Appprop img={"assents/gif/supportgif.gif"} h3={"Technical Support"} p={"We firmly believe that the technical support influences customer’s buying decisions. Thus, we strive to exceed our client’s expectations by providing excellent product support"} />
                    </div>
                </div>
            </div>

            <div className="idea-main" data-aos="zoom-in-down">
                <div className="container">
                    <div className="idea-inner-main">

                        <div className="idea-child" >
                            <div className="idea-inner-child" >
                                <img src="assents/img/gala7.jpg" alt="" />
                            </div>
                        </div>
                        <div className="idea-child" >
                            <div className="idea-inner-child">
                                <h2>Your Strategic IT Partner From Idea to Implementation</h2>

                                <p>When you choose GalaxyERP as your partner for software development, you add real value to your organization through domain expertise, proven technology & methodologies and world-class service. By synergizing your operations with us, you will find your IT partner who keeps you always moving forward.</p>
                                <ul>
                                    <li>Software Product Development Service (10+ Years of Experience)</li>
                                    <li>Domain & Tech Expert Team, High-end Programming</li>
                                    <li>Low Operating Cost, Transparent Business</li>
                                    <li>Global Exposure, IPR Protection</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="serve-main" data-aos="fade-down">
                <div className="container">
                    <div className="serve-text">
                        <p className='serve-head'>Industries We Serve</p>
                        <p className='serve-last'>We serve multiple industries globally. Our domain expertise is backed by our phenomenal experience of successfully conceptualizing and delivering <span>100+ projects.</span> </p>
                    </div>
                    <div className="serve-inner-main">
                        <Serveprop img={"assents/img/serve1.jpg"} text={"Textiles"} />
                        <Serveprop img={"assents/img/serve2.jpg"} text={"Technical Textile"} />
                        <Serveprop img={"assents/img/serve3.jpg"} text={"Dyeing"} />
                        <Serveprop img={"assents/img/serve4.jpg"} text={"Processing"} />
                        <Serveprop img={"assents/img/serve5.jpg"} text={"Knitting"} />
                        <Serveprop img={"assents/img/serve6.jpg"} text={"Weaving"} />
                        <Serveprop img={"assents/img/serve7.jpg"} text={"Garmenting"} />
                        <Serveprop img={"assents/img/serve8.jpg"} text={"Manufacturing textile"} />
                        <Serveprop img={"assents/img/serve9.jpg"} text={"Carpet Industry "} />
                        <Serveprop img={"assents/img/serve10.jpg"} text={"Chemical manufacturing"} />
                        <Serveprop img={"assents/img/serve11.jpg"} text={"Gen AI App devlopment"} />
                        <Serveprop img={"assents/img/serve12.jpg"} text={"Legacy App development  "} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Home
