import React from 'react'

const Feaprop = (props) => {
  return (
    <>
        {/* <div className="feature-child">
            <div className="feature-inner-child">
             <img src={i.png} alt="" />   
                <p>{i.p}</p>
            </div>
        </div> */}
         <div className="feature-main-child">
                <div className="feature-child-inner">
                    <div className="f-images"><img src={props.fimg} alt="" /></div>
                    <div className="f-txt">{props.ftxt}</div>
                </div>
            </div>
    </>
  )
}

export default Feaprop
