import React from 'react'

const Moduleprop = (props) => {
    return (
        <>
            {/* <div className="module-child">
                <div className="module-inner-child">
                    <a href="">
                        <img src={m.img} alt="" />
                    </a>
                    <p>{m.name}</p>
                </div>
            </div> */}
             <div className="modules-chils">
                <div className="modules-child-inner">
                    <img src={props.imges} alt="" />
                    <div className="txtmodules">{props.txtmodules}</div>
                </div>
            </div>
        </>
    )
}

export default Moduleprop
