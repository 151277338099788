import React from 'react';
import Feaprop from './Feaprop';

const Features = () => {
    return (
        <>
            {/* <div className="feature-main">
            <div className="container">
                <div className="feature-text">
                    <p className='feature-head'>FEATURES</p>
                    <p className='feature-small'>Standard Features</p>
                </div>
                <div className="feature-inner-main">
                        <Feaprop png={"assents/img/f1.png"} p={"Customised GST Invoice with Logo."}/>
                        <Feaprop png={"assents/img/f2..png"} p={"Generate GSTR-1,GSTR-2,GSTR-3B Reports"}/>
                        <Feaprop png={"assents/img/f3.png"} p={"Send Auto SMS in Sale bills and Receipts"}/>
                        <Feaprop png={"assents/img/f4.png"} p={"Auto Reminders For Post Dated Cheques"}/>
                        <Feaprop png={"assents/img/f5.png"} p={"Auto Reminders For Over Due Bills."}/>
                        <Feaprop png={"assents/img/f6.png"} p={"Print Reports Column as per Choice"}/>
                        <Feaprop png={"assents/img/f7.png"} p={"Set Credit Limits For Customers"}/>
                        <Feaprop png={"assents/img/f8.png"} p={"Sale Bill Printing As Per Customerwise,Billwise"}/>
                        <Feaprop png={"assents/img/f9.png"} p={"Outstanding Reports with 30,60,90 Days"}/>
                        <Feaprop png={"assents/img/f10.png"} p={"Cheque And Envelop Printing"}/>
                        <Feaprop png={"assents/img/f11.png"} p={"User Logs Reports"}/>
                        <Feaprop png={"assents/img/f12.png"} p={"Userwise Login Access Rights"}/>
                        <Feaprop png={"assents/img/f13.png"} p={"Auto Check Valid GST NO. through GST Portal."}/>
                        <Feaprop png={"assents/img/f14.png"} p={"Generate E-Way Bills"}/>
                        <Feaprop png={"assents/img/f15.png"} p={"Export All Reports In Excel, CSV, PDF Formats."}/>
                        <Feaprop png={"assents/img/f16.png"} p={"Auto Data Backup."}/>
                        <Feaprop png={"assents/img/f17.png"} p={"Generate MIS Reports."}/>
                        <Feaprop png={"assents/img/f18.png"} p={"Generate Customer, Supplier and General Ledgers"}/>
                        <Feaprop png={"assents/img/f19.png"} p={"Generate Financial Reports"}/>
                        <Feaprop png={"assents/img/f20.png"} p={"Bank Reconcilation Statement."}/>
                        <Feaprop png={"assents/img/f21.png"} p={"Backup to Google Drive."}/>
                        <Feaprop png={"assents/img/f22.png"} p={"Integration with the Third-Party Application."}/>
                        <Feaprop png={"assents/img/f23.png"} p={"Chatbot"}/>
                </div>
            </div>
        </div> */}

            <div className="feature-main-page">
                <div className="container">
                    <div className="feature-txt-main">
                        <h1>FEATURES</h1>
                        <p>Standard Features</p>
                    </div>
                    <div className="feature-inner-main">
                        <Feaprop fimg="assents/img/f1.png" ftxt="Customised GST Invoice with Logo." />
                        <Feaprop fimg="assents/img/f2..png" ftxt="Generate GSTR-1,GSTR-2,GSTR-3B Reports" />
                        <Feaprop fimg="assents/img/f3.png" ftxt="Send Auto SMS in Sale bills and Receipts" />
                        <Feaprop fimg="assents/img/f4.png" ftxt="Auto Reminders For Post Dated Cheques" />
                        <Feaprop fimg="assents/img/f5.png" ftxt="Auto Reminders For Over Due Bills." />
                        <Feaprop fimg="assents/img/f6.png" ftxt="Print Reports Column as per Choice" />
                        <Feaprop fimg="assents/img/f7.png" ftxt="Set Credit Limits For Customers" />
                        <Feaprop fimg="assents/img/f8.png" ftxt="Sale Bill Printing As Per Customerwise,Billwise" />
                        <Feaprop fimg="assents/img/f9.png" ftxt="Outstanding Reports with 30,60,90 Days" />
                        <Feaprop fimg="assents/img/f10.png" ftxt="Cheque And Envelop Printing" />
                        <Feaprop fimg="assents/img/f11.png" ftxt="User Logs Reports" />
                        <Feaprop fimg="assents/img/f12.png" ftxt="Userwise Login Access Rights" />
                        <Feaprop fimg="assents/img/f13.png" ftxt="Auto Check Valid GST NO. through GST Portal." />
                        <Feaprop fimg="assents/img/f14.png" ftxt="Generate E-Way Bills" />
                        <Feaprop fimg="assents/img/f15.png" ftxt="Export All Reports In Excel, CSV, PDF Formats." />
                        <Feaprop fimg="assents/img/f16.png" ftxt="Auto Data Backup." />
                        <Feaprop fimg="assents/img/f17.png" ftxt="Generate MIS Reports." />
                        <Feaprop fimg="assents/img/f18.png" ftxt="Generate Customer, Supplier and General Ledgers" />
                        <Feaprop fimg="assents/img/f19.png" ftxt="Generate Financial Reports" />
                        <Feaprop fimg="assents/img/f20.png" ftxt="Bank Reconcilation Statement." />
                        <Feaprop fimg="assents/img/f21.png" ftxt="Backup to Google Drive." />
                        <Feaprop fimg="assents/img/f22.png" ftxt="Integration with the Third-Party Application." />
                        <Feaprop fimg="assents/img/f23.png" ftxt="Chatbot" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features
