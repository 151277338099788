import React from 'react';
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCall } from "react-icons/io5";
// import { DownOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
// import { Dropdown, Space } from 'antd';
import { Button } from 'antd';
import { Link } from 'react-scroll';
import Mininav from './Mininav';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const Head = () => {
    const items = [
  {
    key: '1',
    type: 'group',
    label: 'Email',
    children: [
      {
        key: '1-1',
        label: 'info@galaxyerpsoftware.com',
      },
    ],
  },
  {
    key: '2',
    type: 'group',
    label: 'Business Enquiry',
    children: [
      {
        key: '1-2',
        label: '+91-9924577750',
      },
    ],
  },
  {
    key: '3',
    type: 'group',
    label: 'Phone',
    children: [
      {
        key: '1-3',
        label: '+91-9638177750',
      },
      {
        key: '1-4',
        label: '+91-8128061833',
      },
    ],
  },
];
    return (
        <>

            <div className="head-main">
                <div className="head-inner-main">
                    <div className="head-child">
                        <div className="head-inner-child">
                            <div className="head-con">
                                <div className="head-parts">
                                    <a href='https://galaxyerpsoftware.com/'>
                                        <img src="assents/img/Galaxy3 (2).jpg" alt="" className='logo-header' />
                                    </a>
                                    <a href='https://galaxyerpsoftware.com/' className='logo2'>
                                        <img src="assents/img/Galaxylogo.png" alt="" />
                                    </a>
                                </div>
                                <div className="head-parts">
                                    <div className="head-buttons">
                                        <div className="head-btn">
                                            <a href="https://cal.com/galaxyerp-team-brxd8h/30min?date=2024-12-05&month=2024-12">
                                                <Button type="" className='dropinfo1'>Make An Inquiry
                                                </Button></a>
                                        </div>
                                        <div className="head-btn">
                                            <Dropdown menu={{ items }} trigger={['click']} className='drop'>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space className='dropinfo'>
                                                        More Info
                                                        <DownOutlined />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        </div>
                                        <div className="head-btn">
                                            <Mininav />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="head-child">
                        <div className="head-inner-child">
                            <div className="nav-menu">
                                <ul className="main-menu">
                                    <li>
                                        <Link to="home"
                                            spy={true}
                                            smooth={true}
                                            offset={-150}
                                            duration={500} >Home</Link>
                                            {/* <a href="#home" >home</a> */}
                                    </li>
                                    <li>
                                        <Link to="about" spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}>about</Link>
                                            {/* <a href="#home" >about</a> */}

                                    </li>
                                   
                                    <li>
                                        <Link to='service' spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}>services</Link>
                                            {/* <a href="#home" >services</a> */}
                                    </li>
                                    <li>
                                        <Link to='techno' spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}>technologies</Link>
                                            {/* <a href="#home" >technologies</a> */}
                                    </li>
                                    <li>
                                        <Link to="about" spy={true}
                                            smooth={true}
                                            offset={840}
                                            duration={500}>gallery</Link>
                                            {/* <a href="#home" >gallery</a> */}
                                    </li>
                                    <li>
                                        <Link to='footer' spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}>contact</Link>
                                            {/* <a href="#home" >contact</a> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Head;
