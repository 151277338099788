import React from "react";


const Ourclients = () => {
    return (
        <div className="logo-main-div">
            <div className="container">
                <div className="main-inner">
                    <div className="title-main">
                        <h2>
                            <span className="size-title">Our-Clients </span>
                            <br /><span className="size-title1">Trust is the foundation of every successful assets.</span>
                        </h2>
                    </div>

                    <div className="slider-container">
                        <div className="slider-track">
                            {/* Add your images here */}
                            <div className="client-logo">
                                <img src="assents/img/gogad.jpg" alt="gogad" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/NTM.jpg" alt="NTM" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/SGF.PNG" alt="SGF" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/shah.jpg" alt="shah" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/gogad.jpg" alt="NTM" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/NTM.jpg" alt="SGF" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/SGF.PNG" alt="gogad" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/shah.jpg" alt="shah" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/gogad.jpg" alt="NTM" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/NTM.jpg" alt="SGF" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/SGF.PNG" alt="gogad" />
                            </div>


                            {/* Duplicate client-logos for seamless scrolling */}
                            <div className="client-logo">
                                <img src="assents/img/shah.jpg" alt="gogad" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/gogad.jpg" alt="NTM" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/NTM.jpg" alt="SGF" />
                            </div>
                            <div className="client-logo">
                                <img src="assents/img/SGF.PNG" alt="shah" />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ourclients;
