import React from 'react'

const Tab3 = () => {
    return (
        <>
            <div className="tab1-main">
                <div className="conatiner">
                    <div className="tab1-inner-main">
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab11.jpg" alt="" />
                                <p>MS SQL</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab13.jpg" alt="" />
                                <p>Oracle</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab14.jpg" alt="" />
                                <p>DB2</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab15.jpg" alt="" />
                                <p>Fox Pro</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab10.jpg" alt="" />
                                <p>MongoDB</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tab3
