import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { IoCall } from "react-icons/io5";
import { FaBusinessTime } from "react-icons/fa";
import { Menu } from 'antd';

const items = [
  {
    key: '1',
    icon: <MailOutlined />,
    label: 'Email',
    children: [
      { key: '11', label: 'info@galaxyerpsoftware.com' },
    ],
  },
  {
    key: '2',
    icon: <FaBusinessTime  />,
    label: 'Business Enquiry',
    children: [
      { key: '21', label: '+91-9924577750'},
    ],
  },
  {
    key: '3',
    icon: <IoCall />,
    label: 'Phone',
    children: [
      { key: '31', label: '+91-9638177750' },
      { key: '32', label: '+91-8128061833' },
    ],
  },
];

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const Menudrop = () => {
  const [stateOpenKeys, setStateOpenKeys] = useState(['2', '23']);

  // Initialize levelKeys from items
  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={['231']}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        style={{
          width: 256,
        }}
        items={items}
      />
    </>
  );
};

export default Menudrop;
