import React from 'react';
import Tab from './Tab';

const Techno = () => {
    return (
        <>
            <div className="techno-main" id='techno'>
                <div className="container">
                    <div className="techno-text">
                        <p className='techno-head'>TECHNOLOGIES</p>
                        <Tab />
                    </div>
                    <div className="techno-inner-main">
                      
                        <div className="techno-child">
                            <div className="techno-inner-child">
                            <p className='techno-small'>Enabling Business with Emerging Technologies</p>
                                <p>Modern business hardly depends on any one technology. We are providing innovative solutions to our clients as per their need. Our continuous research and development helps us to satisfy our clients' needs with cutting edge technologies. We are known for our skilled professionals, expertise in diverse platforms, well-established methodologies, delivery of robust services and helping our clients to maximize their business performance. Our 20+ years of experience in software industry help us to deliver integrated, scalable, robust solutions at minimum costs.</p>
                            </div>
                        </div>
                        <div className="techno-child">
                            <div className="techno-inner-child">
                                <img src="assents/img/techno1.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tech-main">
    <div className="container">
        <div className="tech-inner-main">
            <div className="tech-child">
                <div className="tech-inner-child">
                    <img src="assents/img/techno2.jpg" alt="" class="tech-img" />
                    <p className='tech-head tech-animate'>Microsoft Technologies</p>
                    <p className='tech-small tech-animate'>GalaxyERP offers mobile applications that are user-friendly and facilitates quick information processing. We offer solutions in a plethora of platforms from Google Android, Xamarin, Ionic etc. to enhance customer engagement and maximize revenue generation.</p>
                    <p className='tech-small tech-animate'>GalaxyERP has the expertise to produce mobile applications with intuitive and user-friendly features. It helps to transform and integrate your business process with the industry-specific mobile app for your software products, solutions, workforce, and customers.</p>
                </div>
            </div>
            <div className="tech-child">
                <div className="tech-inner-child">
                    <img src="assents/img/techno3.jpg" alt="" class="tech-img" />
                    <p className='tech-head tech-animate'>Mobile Technologies</p>
                    <p className='tech-small tech-animate'>GalaxyERP recognizes that Microsoft technologies are continually expanding, making it easier for all sources of communication to work with one another, and providing platforms for large and small companies as well as individuals. Galaxy ERP has been working on Microsoft technologies since its inception.</p>
                    <p className='tech-small tech-animate'>GalaxyERP is the right choice to develop mobile applications, web applications as well as desktop applications using Microsoft technologies. With expertise in .NET, Azure, and other Microsoft tools, we ensure seamless integration and robust performance.</p>
                </div>
            </div>
        </div>
    </div>
</div>


        </>
    )
}

export default Techno;
