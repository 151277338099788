import React, { useEffect } from 'react';
import { MdOutlineCheck } from "react-icons/md";
import Slide1 from './Slide1';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Ourclients from './Ourclients';

const About = () => {
    useEffect(() => {
        AOS.init();
    })
    return (
        <>
            {/* <div className="about-main" id='about'>
                <div className="container">
                    <div className="about-inner-main">
                        <div className="about-child">
                            <div className="about-inner-child" d>
                                <img src="assents/img/about.jpg" alt="" />
                            </div>
                        </div>
                        <div className="about-child">
                            <div className="about-inner-child">
                                <p className='about-head'>ABOUT US</p>
                                <div className="about-parts">
                                    <div className="about-part">
                                        <p className='about-text'>At GalaxyERP Software Private Limited, Empower Your Business with Next-Gen ERP Solutions, Redefining Efficiency and Innovation. Seamlessly Integrate Data and Automation to Propel Your Business Towards Unprecedented Success with State-of-the-Art ERP Technology.</p>
                                    </div>
                                    <div className="about-part">
                                        <ul>
                                            <p className='about-lists'>Why Choose Us?</p>
                                            <li><MdOutlineCheck className='about-icon' />Multiple Production Houses</li>
                                            <li><MdOutlineCheck className='about-icon' />Multiple Warehouse Management</li>
                                            <li><MdOutlineCheck className='about-icon' />Production Process Defination</li>
                                            <li><MdOutlineCheck className='about-icon' />Job Work Management</li>
                                            <li><MdOutlineCheck className='about-icon' />Generates financial reports</li>
                                            <li><MdOutlineCheck className='about-icon' />Better Inventory Management</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="about-main">

                <div className="about-main-page-wrapper" id='about'>
                    <div className="about-main-page">
                        <div className="container">
                            <div className="about-main-inner">
                                <div className="img-part">
                                    <img src="assents/img/about.jpg" alt="About GalaxyERP Software" />
                                </div>
                                <div className="content-part">
                                    <div className="content-part-inner">
                                        <div className="txtt">
                                            <h1><b>ABOUT US</b></h1>
                                            <p>At GalaxyERP Software Private Limited, Empower Your Business with Next-Gen ERP Solutions, Redefining Efficiency and Innovation. Seamlessly Integrate Data and Automation to Propel Your Business Towards Unprecedented Success with State-of-the-Art ERP Technology.</p>
                                        </div>
                                        <ul className="list">
                                            <h1>Why Choose Us?</h1>
                                            <li>Multiple Production Houses</li>
                                            <li>Multiple Warehouse Management</li>
                                            <li>Production Process Definition</li>
                                            <li>Job Work Management</li>
                                            <li>Generates financial reports</li>
                                            <li>Better Inventory Management</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Ourclients />
            <div className="erp-main">
                <div className="container">
                    <div className="erp-text">
                        <p>Life at GalaxyERP Software Private Limited</p>
                    </div>
                    <div className="erp-inner-main">
                        <Slide1 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default About
