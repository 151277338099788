import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import About from './About';
import Service from './Service';
import Techno from './Techno';
import Footer from './Footer';
import Head from './Head';
import Scrolltotop from './Scrolltotop';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <Head/>
  <Home/>
  <About/>
  <Service/>
  <Techno/>
  <Footer/>
  <Scrolltotop/>
  </>
);


