import React from 'react'
import Moduleprop from './Moduleprop'

const Module = () => {
    return (
        <>
            {/* <div className="module-main">
                <div className="container">
                    <div className="module-text">
                        <p>GalaxyERP Modules</p>
                    </div>
                    <div className="module-inner-main">
                        <Moduleprop img={"assents/img/module1.jpg"} name={"Base"}/>
                        <Moduleprop img={"assents/img/module2.jpg"} name={"Sales"}/>
                        <Moduleprop img={"assents/img/module3.jpg"} name={"Production Planning"}/>
                        <Moduleprop img={"assents/img/module4.jpg"} name={"Production"}/>
                        <Moduleprop img={"assents/img/module5.jpg"} name={"Purchasing Procurement"}/>
                        <Moduleprop img={"assents/img/module6.jpg"} name={"Inventory transportation"}/>
                        <Moduleprop img={"assents/img/module7.jpg"} name={"Costing"}/>
                        <Moduleprop img={"assents/img/module8.jpg"} name={"Finance "}/>
                        <Moduleprop img={"assents/img/module9.jpg"} name={"Plant maintenance"}/>
                        <Moduleprop img={"assents/img/module10.jpg"} name={"Payroll & HR"}/>
                        <Moduleprop img={"assents/img/module11.jpg"} name={"CRM"}/>
                        <Moduleprop img={"assents/img/module12.jpg"} name={"Business Dashboard "}/>
                    </div>
                </div>
            </div> */}
                <div className="modules-main">
                    <div className="container">
                        <div className="modules-txt-main">
                            <h1>GalaxyERP Modules</h1>
                        </div>
                        <div className="modules-main-inner">
                            <Moduleprop imges="assents/img/module1.jpg" txtmodules="Base"/>
                            <Moduleprop imges="assents/img/module2.jpg" txtmodules="Sales"/>
                            <Moduleprop imges="assents/img/module3.jpg" txtmodules="Production Planning"/>
                            <Moduleprop imges="assents/img/module4.jpg" txtmodules="Production"/>
                            <Moduleprop imges="assents/img/module5.jpg" txtmodules="Purchasing Procurement"/>
                            <Moduleprop imges="assents/img/module6.jpg" txtmodules="Inventory transportation"/>
                            <Moduleprop imges="assents/img/module7.jpg" txtmodules="Costing"/>
                            <Moduleprop imges="assents/img/module8.jpg" txtmodules="Finance"/>
                            <Moduleprop imges="assents/img/module9.jpg" txtmodules="Plant maintenance"/>
                            <Moduleprop imges="assents/img/module10.jpg" txtmodules="Payroll & HR"/>
                            <Moduleprop imges="assents/img/module11.jpg" txtmodules="CRM"/>
                            <Moduleprop imges="assents/img/module12.jpg" txtmodules="Base"/>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default Module
