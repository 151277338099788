import React, { useState, useEffect } from 'react';

const Scrolltotop = () => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const Top = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {showButton && (
                <div className="scroll-main">
                    <button type="button" onClick={Top} className="top-btn">
                        <i className="arrow up"></i>
                    </button>
                </div>
            )}
        </>
    );
};

export default Scrolltotop;
