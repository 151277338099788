import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slide1 = () => {
    const options = {
        loop: true,
        margin: 20,
        items: 4,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        smartSpeed: 900,

        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            700: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    };
    return (
        <>
            <OwlCarousel className='owl-theme' {...options}>
                <div class='item'>
                        <a href="#"><img src="assents/img/photo1.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo2.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo3.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo4.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo5.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo6.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo20.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo7.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo8.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo9.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo10.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo11.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo12.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo13.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo17.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo18.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo19.jpg" alt="" /></a>
                </div>
                <div class='item'>
                   <a href="#"> <img src="assents/img/photo21.jpg" alt="" /></a>
                </div>
               
            </OwlCarousel>
        </>
    )
}

export default Slide1
