import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { FaBars } from "react-icons/fa";
import { Link } from 'react-scroll';
import Menudrop from './Menudrop';

const Mininav = () => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer} className='mininavicon'>
                <FaBars />
            </Button>
            <Drawer title="GalaxyERP Software Private Limited" onClose={onClose} open={open} className='minidrawer'>
                <ul className="main-menu">
                    <li>
                        <Link to="home"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500} >Home</Link>
                    </li>
                    <li>
                        <Link to="about" spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}>about</Link>
                    </li>
                  
                    <li>
                        <Link to='service' spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}>services</Link>
                    </li>
                    <li>
                        <Link to='techno' spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}>technologies</Link>
                    </li>
                    <li>
                        <Link to="about" spy={true}
                            smooth={true}
                            offset={350}
                            duration={500}>Gallery</Link>
                    </li>
                    <li>
                        <Link to='footer' spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}>contact</Link>
                <Menudrop/>
                    </li>
                </ul>
            </Drawer>

        </>
    )
}

export default Mininav;
