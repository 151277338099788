// import React from 'react';
// import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa6";

// const FooterEnd = () => {
//     return (
//         <>
//             <div className="end-main">
//                 <div className="container">
//                     <div className="end-inner-main">
//                         <div className="end-child">
//                             <div className="end-inner-child">
//                                 <p>Copyright © 2024 GalaxyERP Software Private Limited. All Rights Reserved.</p>
//                             </div>
//                         </div>
//                         <div className="end-child">
//                             <div className="end-inner-child">
//                                 <ul className="end-list">
//                                     <li>
//                                         <a href='https://www.facebook.com/profile.php?id=61557637277831'><FaFacebookF /></a>
//                                     </li>
//                                     {/* <li>
//                                         <a href=''> <FaTwitter /></a>
//                                     </li> */}
//                                     <li>
//                                         <a href="https://www.linkedin.com/company/galaxy-erp-software-private-limited/"><FaLinkedinIn /></a>
//                                     </li>
//                                     <li>
//                                         <a href='https://www.instagram.com/galaxyerpsoftwarepvtltd/'><FaInstagram  /></a>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default FooterEnd
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const FooterEnd = () => {
    return(
        <>
            <div className="social-main-page">
                <div className="container">
                    <div className="social-main-inner">
                        <div className="social-content">
                            <p>Copyright © 2024 GalaxyERP Software Private Limited. All Rights Reserved.</p>
                        </div>
                        <div className="social-icon">
                            <div className="social-icon-inner">
                                <div className="social-icon"><a href="https://www.facebook.com/people/Galaxy-ERP/61557637277831/" target="_blank"><FaFacebook className="endicon"/></a></div>
                                <div className="social-icon"><a href="https://www.linkedin.com/company/galaxy-erp-software-private-limited/" target="_blank"><FaLinkedin className="endicon" /></a></div>
                                <div className="social-icon"><a href="https://www.instagram.com/galaxyerpsoftwarepvtltd/"><RiInstagramFill className="endicon"/></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterEnd;